import {getApiString} from "./getApi";
import {baseUrl, appKey} from './serverConstants';

export function deleteGame(gameId, playerKey, completion) {
    let url = baseUrl;
    url += "/deleteGame";
    url += "/" + appKey;
    url += "/" + gameId;
    url += "/" + playerKey;

    getApiString(url, completion);
}