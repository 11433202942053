import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    Text,
    SimpleGrid,
    Button
} from "@chakra-ui/react";
import {CardView} from "../Helper/cardView";
import {useState, useEffect} from "react";
import {postToGameWithCompletion} from "../API/postToGame";
import {setCookie, readCookieValue} from "../Helper/cookies";
import {allPlayers} from "../Helper/allPlayers";

export function NightView(props) {
    const [drawerIsOpen, setDrawerIsOpen] = useState(false);

    // Drawer management
    useEffect(() => {
        if (props.gameFile.mafia.id === props.playerId
            || props.gameFile.detective.id === props.playerId
            || props.gameFile.doctor.id === props.playerId) {
            console.log("open drawer");
            setDrawerIsOpen(true);
        }
    }, [props.gameFile.id, props.gameFile.mafia.id, props.gameFile.detective.id, props.gameFile.doctor.id, props.playerId]);
    const onDrawerClose = () => {
        setDrawerIsOpen(false);
    };

    const buttonTapped = player => {
        onDrawerClose();

        props.setDetectiveCompletion(false);
        if (props.gameFile.detective.id === props.playerId) {
            props.setDetectiveCompletion(true);
            if (props.gameFile.mafia.id === player) {
                props.displayAlert("Mafia!");
            } else {
                props.displayAlert("Villager.");
            }
            return;
        } else if (props.gameFile.doctor.id === props.playerId) {
            setCookie("doctorLast", player);
        }

        const message = "special-character:" + getCharacterName(props.gameFile, props.playerId) + ":" + player;
        postToGameWithCompletion(props.gameFile.gameId, props.playerKey, message, () => {
            props.forceReload();
        });
    };

    let buttons = [];
    const all = allPlayers(props.gameFile);
    for (const playerIndex in all) {
        const player = all[playerIndex];
        if (props.gameFile.dead.includes(player.id) || (props.gameFile.doctor.id === props.playerId && player.id === readCookieValue("doctorLast"))) {
            buttons.push(
                <Button
                    isDisabled={true}
                    key={player.id}
                >
                    {player.name}
                </Button>
            );
        } else {
            buttons.push(
                <Button
                    onClick={() => buttonTapped(player.id)}
                    key={player.id}
                >
                    {player.name}
                </Button>
            );
        }
    }

    return (
        <>
            <CardView backgroundColor="black">
                <Text
                    fontSize="4xl"
                    fontWeight="black"
                >
                    Night fell on the city...
                </Text>
            </CardView>

            <Drawer
                isOpen={drawerIsOpen}
                placement="right"
                onClose={onDrawerClose}
                closeOnEsc={false}
                closeOnOverlayClick={false}
            >
                <DrawerOverlay>
                    <DrawerContent>
                        <DrawerHeader>
                            {getHeading(props.gameFile, props.playerId)}
                        </DrawerHeader>
                        <DrawerBody>
                            <SimpleGrid
                                columns={1}
                                spacingY="20px"
                            >
                                {buttons}
                            </SimpleGrid>
                        </DrawerBody>
                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>
        </>
    );
}

function getHeading(gameFile, playerId) {
    if (playerId === gameFile.mafia.id) {
        return "Choose who to kill:";
    } else if (playerId === gameFile.detective.id) {
        return "Choose who to investigate:";
    } else if (playerId === gameFile.doctor.id) {
        return "Choose who to save:";
    }
    return "";
}

function getCharacterName(gameFile, playerId) {
    if (playerId === gameFile.mafia.id) {
        return "mafia";
    } else if (playerId === gameFile.detective.id) {
        return "detective";
    } else if (playerId === gameFile.doctor.id) {
        return "doctor";
    }
    return "villager";
}