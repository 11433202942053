import {Box, VStack, Center, useBreakpointValue, Text} from "@chakra-ui/react";
import {VerticalAd, HorizontalAd, vertical1, vertical2, horizontal1, horizontal2} from "./ads";

export function ScrollCardView(props) {
    const vertical = useBreakpointValue([false, false, true], {ssr: false});

    if (vertical) {
        return (
            <Center
                backgroundColor={props.backgroundColor || "white"}
                paddingTop={props.paddingTop || ["24px", "32px", "40px"]}
                paddingBottom={props.paddingBottom || ["24px", "32px", "40px"]}
            >
                <VerticalAd adSlot={vertical1} />
                <VStack
                spacing="24px"
                    width={["100%", "80%", "60%", "40%"]}
                >
                <Box
                    backgroundColor="gray.1"
                    padding="20px"
                    borderRadius="lg"
                    shadow="md"
                    
                >
                    <VStack
                        spacing="24px"
                        align={props.stackAlign || "left"}
                    >
                        {props.children}
                    </VStack>
                    </Box>
                    <Text color="gray.4">
                        Ads on this page help support running the game servers.
                    </Text>
                </VStack>
                <VerticalAd adSlot={vertical2} />
            </Center>
        );
    } else {
        return (
            <Center
                backgroundColor={props.backgroundColor || "white"}
                paddingTop={props.paddingTop || ["24px", "32px", "40px"]}
                paddingBottom={props.paddingBottom || ["24px", "32px", "40px"]}
            >
                <VStack spacing="24px">
                    <HorizontalAd adSlot={horizontal1} />
                    <Box
                        backgroundColor="gray.1"
                        padding="20px"
                        borderRadius="lg"
                        shadow="md"
                        width={["100%", "80%", "60%", "40%"]}
                    >
                        <VStack
                            spacing="24px"
                            align={props.stackAlign || "left"}
                        >
                            {props.children}
                        </VStack>
                    </Box>
                    <Text color="gray.4">
                        Ads on this page help support running the game servers.
                    </Text>
                    <HorizontalAd adSlot={horizontal2} />
                </VStack>
            </Center>
        );
    }

    
}