import {useEffect, useState} from 'react';
import {readCookieValue, setCookie} from "../Helper/cookies";
import {getGameFile} from '../API/getGameFile';
import {postToGameWithCompletion} from "../API/postToGame";
import {openJoin} from "../Helper/links";
import {
    AlertDialog,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogBody,
    Button,
    Center,
    Spinner
} from "@chakra-ui/react"

// Pages
import {DeadView} from './deadView';
import {EndView} from "./endView";
import {NightView} from "./nightView";
import {SetupView} from "./setupView";
import {VoteView} from "./voteView";
import { LogPlayPage } from '../Helper/analytics';

const reloadFreq = 4000;

export function PlayPage() {
    useEffect(LogPlayPage, []);

    // Cookies
    const gameId = readCookieValue('gameId');
    const playerId = readCookieValue('playerId');
    const playerKey = readCookieValue('playerKey');
    // const playerName = readCookieValue('playerName');

    // Game loop
    const [loadedGameFile, setLoadedGameFile] = useState(null);
    const [gameFile, setGameFile] = useState(null);
    const [storytellingDismissal, setStorytellingDismissal] = useState(false);

    useEffect(() => {
        if (!loadedGameFile) return;
        if (!gameFile) {
            setGameFile(loadedGameFile);
            return;
        }

        if (loadedGameFile.deleted) {
            displayAlert("The game has ended.");
            setGameFile(loadedGameFile);
        } else if (gameFile !== null) {
            if (loadedGameFile.storytelling) {
                if (loadedGameFile.storytellerId === playerId) {
                    displayAlert("Tell the story: " + loadedGameFile.storytellerAlert);
                    setStorytellingDismissal(true);
                }
                // Do NOT set the file
                return;
            } else {
                setGameFile(loadedGameFile);
                if (loadedGameFile.alertId !== gameFile.alertId) {
                    console.log("setting game file: " + loadedGameFile.gameState + loadedGameFile.alertId);
                    if (loadedGameFile.alert === "set-character") {
                        if (loadedGameFile.mafia.id === playerId) {
                            displayAlert("Your role: mafia.")
                        } else if (loadedGameFile.detective.id === playerId) {
                            displayAlert("Your role: detective.")
                        } else if (loadedGameFile.doctor.id === playerId) {
                            displayAlert("Your role: doctor.")
                        } else if (loadedGameFile.sheriff.id === playerId && loadedGameFile.sheriff.used) {
                            displayAlert("Your role: sheriff.")
                        } else {
                            displayAlert("Your role: villager.")
                        }
                    } else {
                        displayAlert(loadedGameFile.alert);
                    }
                }
            }
        }
    }, [loadedGameFile, gameFile, playerId]);

    useEffect(() => {
        const reloadFile = () => {
            getGameFile(gameId, playerKey, newFile => {
                setLoadedGameFile(newFile);
            });
        };

        reloadFile();

        const interval = setInterval(() => {
            reloadFile();
        }, reloadFreq);

        return () => clearInterval(interval);
    }, [gameId, playerKey]);

    const forceReload = () => {
        getGameFile(gameId, playerKey, newFile => {
            setLoadedGameFile(newFile);
        });
    };

    // Alert
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [alertContentText, setAlertContentText] = useState('');
    const [detectiveCompletion, setDetectiveCompletion] = useState(false);
    const displayAlert = (heading, content) => {
        console.log("Display alert: " + heading);
        setAlertText(heading);
        if (content) {
            setAlertContentText(content);
        }
        setAlertOpen(true);
    };
    const onCloseAlert = ({target}) => {
        console.log("on close alert");
        setAlertOpen(false);
        if (gameFile.deleted) {
            openJoin();
        } else if (storytellingDismissal) {
            postToGameWithCompletion(gameId, playerKey, "storyteller-done", forceReload);
            setStorytellingDismissal(false);
        } else if (gameFile.gameState === "night" && gameFile.detective.id === playerId && detectiveCompletion) {
            postToGameWithCompletion(gameFile.gameId, playerKey, "special-character:detective:null", forceReload);
            setDetectiveCompletion(false);
        }
    };
    const setDetectiveCompletionChild = val => {
        setDetectiveCompletion(val);
    };

    // Display correct body
    let body = <></>;
    if (gameFile === null) {
        body = (
            <Center
                height="100vh"
            >
                <Spinner />
            </Center>
        );
    } else if (gameFile.gameState === "setup") {
        setCookie("doctorLast", "");
        body = <SetupView gameFile={gameFile} playerId={playerId} playerKey={playerKey} forceReload={forceReload} />;
    } else if (gameFile.gameState === "end") {
        setCookie("doctorLast", "");
        body = <EndView gameFile={gameFile} playerId={playerId} playerKey={playerKey} forceReload={forceReload} />;
    } else if (gameFile.dead.includes(playerId)) {
        body = <DeadView />;
    } else if (gameFile.gameState === "night") {
        body = <NightView gameFile={gameFile} playerId={playerId} playerKey={playerKey} displayAlert={displayAlert} setDetectiveCompletion={setDetectiveCompletionChild} forceReload={forceReload} />;
    } else if (gameFile.gameState === "vote") {
        body = <VoteView gameFile={gameFile} playerId={playerId} playerKey={playerKey} displayAlert={displayAlert} forceReload={forceReload} />;
    } else {
        console.log(gameFile);
        body = <h1>Error</h1>
    }

    return (
        <>
            {body}

            <AlertDialog
                isOpen={alertOpen}
            >
                <AlertDialogOverlay />
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            {alertText}
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            {alertContentText}
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button
                                onClick={onCloseAlert}
                            >
                                OK
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
            </AlertDialog>
        </>
    );
}