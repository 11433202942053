export function openHome() {
    window.location = "/";
}

export function openJoin() {
    window.location = "/join";
}

export function openPlay() {
    window.location = "/play";
}

export function openLegal() {
    window.location = "/legal";
}

export function openTerms() {
    window.location = "/terms";
}

export function openPrivacy() {
    window.location = "/privacy";
}

export function openCookies() {
    window.location = "/cookies";
}

export function openSupport() {
    window.location = "/support";
}

export function openDownload() {
    window.open("https://apps.apple.com/app/mafia-the-game/id6475014131");
}

export function openHowToPlay() {
    window.location = "/how-to-play";
}
