import {CardView} from "../Helper/cardView";
import {Button, Text, VStack, Wrap, WrapItem} from "@chakra-ui/react";
import {openJoin, openDownload} from "../Helper/links";

export function HomeHeader() {
    return (
        <CardView
            backgroundColor="red"
            backgroundHeight={["60vh", "70vh", "80vh"]}
            stackAlign="center"
        >
            <VStack>
                <Text
                    fontSize={["4xl", "5xl", "6xl"]}
                    fontWeight="black"
                    align="center"
                >
                    Mafia: The Game
                </Text>
                <Text
                    fontSize="2xl"
                    fontWeight="bold"
                    align="center"
                >
                    The classic social mystery
                </Text>
            </VStack>
            <Wrap
                spacing="24px"
                justify="center"
            >
                <WrapItem>
                    <Button
                        variant="solid"
                        backgroundColor="red"
                        color="white"
                        onClick={openJoin}
                    >
                        Play on the web
                    </Button>
                </WrapItem>
                <WrapItem>
                    <Button
                        variant="solid"
                        backgroundColor="red"
                        color="white"
                        onClick={openDownload}
                    >
                        Download the app
                    </Button>
                </WrapItem>
            </Wrap>
        </CardView>
    );
}