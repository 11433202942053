import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerFooter,
    DrawerOverlay,
    DrawerContent,
    Text,
    SimpleGrid,
    Button
} from "@chakra-ui/react";
import {CardView} from "../Helper/cardView";
import {useState} from "react";
import {postToGameWithCompletion} from "../API/postToGame";
import {allPlayers} from "../Helper/allPlayers";

export function VoteView(props) {

    // Drawer management
    const [drawerIsOpen, setDrawerIsOpen] = useState(true);
    const onDrawerClose = () => {
        setDrawerIsOpen(false);
    };

    // Button handling
    const buttonTapped = player => {
        const message = "vote:" + player + ":" + props.playerId;
        // postToGame(props.gameFile.gameId, props.playerKey, message);
        postToGameWithCompletion(props.gameFile.gameId, props.playerKey, message, str => {
            console.log("completion: " + str);
            props.forceReload();
        });
        onDrawerClose();
    };

    // Button generation
    let buttons = [];
    const all = allPlayers(props.gameFile);
    for (const playerIndex in all) {
        const player = all[playerIndex];
        if (props.gameFile.dead.includes(player.id)) {
            buttons.push(
                <Button
                    isDisabled={true}
                    key={player.id}
                >
                    {player.name}
                </Button>
            );
        } else {
            buttons.push(
                <Button
                    onClick={() => buttonTapped(player.id)}
                    key={player.id}
                >
                    {player.name}
                </Button>
            );
        }
    }

    return (
        <>
            <CardView>
                <Text
                    fontSize="4xl"
                    fontWeight="black"
                >
                    {drawerIsOpen ? "Cast your vote..." : "Waiting for other players to vote."}
                </Text>
            </CardView>

            <Drawer
                isOpen={drawerIsOpen}
                placement="right"
                onClose={onDrawerClose}
                closeOnEsc={false}
                closeOnOverlayClick={false}
            >
                <DrawerOverlay>
                    <DrawerContent>
                        <DrawerHeader>
                            Cast your vote
                        </DrawerHeader>
                        <DrawerBody>
                            <SimpleGrid
                                columns={1}
                                spacingY="20px"
                            >
                                {buttons}
                            </SimpleGrid>
                        </DrawerBody>
                        <DrawerFooter>
                            <Button
                                variant="outline"
                                borderColor="gray.4"
                                onClick={() => buttonTapped("")}
                            >
                                Abstain
                            </Button>
                        </DrawerFooter>
                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>
        </>
    );
}