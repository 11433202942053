import {CardView} from "../Helper/cardView";
import {Button, Text, HStack} from "@chakra-ui/react";
import {postToGameWithCompletion} from "../API/postToGame";
import {deleteGame} from "../API/deleteGame";
import {openJoin} from "../Helper/links";

export function EndView(props) {
    if (!props.gameFile.tvHost && props.gameFile.players[0].id === props.playerId) {
        const newGame = () => {
            postToGameWithCompletion(props.gameFile.gameId, props.playerKey, "reset", () => {
                props.forceReload();
            });
        };
        const leaveGame = () => {
            postToGameWithCompletion(props.gameFile.gameId, props.playerKey, "leave:" + props.playerId + ":null", () => openJoin());
        };
        const disbandGame = () => {
            deleteGame(props.gameFile.gameId, props.playerKey, () => openJoin());
        };
        return (
            <CardView>
                <Text
                    fontSize="4xl"
                    fontWeight="black"
                    color="black"
                >
                    Game over.
                </Text>
                <HStack>
                    <Button
                        size="lg"
                        backgroundColor="red"
                        color="white"
                        variant="solid"
                        onClick={newGame}
                    >
                        New game
                    </Button>
                    <Button
                        size="lg"
                        backgroundColor="red"
                        color="white"
                        variant="solid"
                        onClick={leaveGame}
                    >
                        Leave game
                    </Button>
                    <Button
                        size="lg"
                        backgroundColor="red"
                        color="white"
                        variant="solid"
                        onClick={disbandGame}
                    >
                        Disband game
                    </Button>
                </HStack>
            </CardView>
        );
    }
    return (
        <CardView>
            <Text
                fontSize="4xl"
                fontWeight="black"
                color="black"
            >
                Game over.
            </Text>
            <Text
                fontSize="xl"
                fontWeight="semibold"
                color="gray.4"
            >
                Waiting for the host to restart the game.
            </Text>
        </CardView>
    );
}