import {Text, VStack} from "@chakra-ui/react";
import {AppFooter} from "../Helper/appFooter";
import ReactMarkdown from "react-markdown";
import { LogAuxPage } from "../Helper/analytics";
import { useEffect } from "react";

const markdown = `
**What is the object of the game?**

Every game, players are assigned a role in the imaginary town. Players can either be a mafia, detective, doctor or villager. Each round, night falls on the town and the mafia can choose someone to kill (amongst other gameplay features). When they awaken to find the dead body, the rest of the village votes on who to convict. The aim of the game  (if you are not the mafia) is to successfully convict the mafia before everyone dies. If you are the mafia, the aim is to kill all the other player before you are convicted.

**What does the mafia do?**

There is one mafia per game. The mafia chooses, each night, who to kill. That player (unless saved by the doctor) will then be dead and removed from the game. The aim of the mafia is to kill all the other players before they are convicted themselves.

**What does the doctor do?**

There is one doctor per game. The doctor chooses, each night, who to save. If that player is selected by the mafia to be killed, they will not die because the doctor has saved them. The doctor cannot select the same person to be saved twice in a row. The doctor can choose to save themselves.

**What does the detective do?**

There is one detective per game. Each night, the detective chooses a player to investigate. They then find out whether that person is the mafia or a villager. The doctor can reveal to the other players that they are in fact the doctor at any time in order to convince them of who to convict but be warned: doing so will make you a target for the mafia.

**What does the sheriff do?**

There is one sheriff per game (if the sheriff setting has been selected during the setup of the game). The sheriff acts like any other villager but their vote counts twice each day, giving them greater influence on the vote's outcome.

**What do the villagers do?**

The villagers make up the remaining population of the imaginary town. They, unlike the other characters, go to sleep at night and do not save/kill/investigate other characters. Come morning, however, they must vote on who they think is the mafia.

**Can I abstain from voting for a round?**

Yes. Just click "abstain" when the voting popup appears. Note that the detective, doctor and mafia cannot abstain from choosing their victims.

**How do I start a game?**

To start a game, head over to https://mafiathegame.benrobinson.dev/join or [download the app](https://apps.apple.com/app/mafia-the-game/id6475014131) and click the button to host a game. Ensure you have provided a valid player name (see below). Then, you can share the join link or game code with your friends. Once you have at least 4 players, you can start the game.

**How do I join a game?**

Ask the host of the game for the game id or a join link. With a link: open the link, provide a valid player name and click join. With a game id: head over to https://mafiathegame.benrobinson.dev/join or [download the app](https://apps.apple.com/app/mafia-the-game/id1548200798). On the website, provide the game id and a valid player name and then click "Join Game". On the app, ensure you have set a valid player name in settings, enter the game id on the home page and then click "Join Game".

**What is a player name?**

A player name is the name that will identify you amongst players in the game. Player names must be between 3 and 99 characters long and must only include letters, numbers and spaces to be valid.

**How do I set my player name?**

On the website, enter your player name in the "Player name" text field when joining a game. On the app, navigate to settings within the app (the gear icon in the top right) and edit the "Player name" text field. You can also set your player name during the onboarding process when you first install the app.

Player name's must not be offensive or otherwise inappropriate as decided by the developer in case of dispute. Using an inappropriate player name could result in a ban from using the game. 

**How does the voting system work?**

Each round, the player with the most votes is convicted and sent to jail. This does not require a full 50% of players to vote for them.

If two players jointly have the same highest number of votes, a tie occurs, and no players are convicted.

**Why can I not start a game?**

There are several reasons why you may be unable to start a game:

- Your internet connection - if you are using the app, please ensure you have a strong internet connection that the app can use to contact the server.
- Invalid player name - if your player name is invalid, you will be unable to start a game. Please see "How do I set my player name?" above to learn more about setting a player name and what constitutes a valid one.
- Server error - our servers can take up to a minute to start up following periods of inactivity. In this case, please wait up to a minute and try again.

**Why can I not join a game?**

There are several reasons why you may be unable to start a game:

- Your internet connection - if you are using the app, please ensure you have a strong internet connection that the app can use to contact the server.
- Invalid player name - if your player name is invalid, you will be unable to start a game. Please see "How do I set my player name?" above to learn more about setting a player name and what constitutes a valid one.
- Invalid game id - check that the host of the game has provided you with a valid game id or game join link. An invalid one will mean you are unable to join the game.
- Invalid game - you can only join a game whilst the game is in the setup phase. Please ensure the game has not yet started in order to join it.
- Server error - our servers can take up to a minute to start up following periods of inactivity. In this case, please wait up to a minute and try again.

**The game randomly ended. What happened?**

There are a number of reasons that a game could suddenly end:

- Number of players - if the number of players in a game falls below 3, the game will end because the game mechanics do not allow for fewer then 3 people to be playing.
- The mafia - if the mafia leaves the game, the game will also end because the game mechanics will no longer work.
- The host - at the end of a game, the host can choose to "Disband Game". This deletes the game for all users rather than just making the host leave.
- Server error - our servers reboot roughly once every 24 hours. At this time, you may lose connection to the servers which will trigger the game to end.
- Inactivity - if a game if inactivity for 2 hours or more, our servers will delete the game automatically.

**I have another problem...**

If the above FAQs do not solve your issue, feel free to contact us at hello@benrobinson.dev.

**I found a bug...**

Great! We love to hear how we can improve the game. Please contact us at hello@benrobinson.dev with details of the bug and how to reproduce it.
`;

export function SupportPage() {
    useEffect(() => LogAuxPage('SupportPage'), []);

    return (
        <>
            <VStack
                spacing="16px"
                marginTop="64px"
                marginBottom="64px"
                marginLeft={["3%", "10%", "16%", "22%"]}
                marginRight={["3%", "10%", "16%", "22%"]}
                align="left"
            >
                <Text
                    fontSize="4xl"
                    fontWeight="bold"
                    color="red"
                >
                    Support
                </Text>
                <ReactMarkdown children={markdown} />
            </VStack>
            <AppFooter/>
        </>
    );
}
