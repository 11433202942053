import {HomeHeader} from "./homeHeader";
import {Rules} from './rules';
import {About} from './about';
import {CardQuote} from './quote';
import {PlayButton} from './playButton';
import {AppFooter} from "../Helper/appFooter";
import {useEffect} from "react";
import {ImageCarousel} from "./imageCarousel";
import {LogHomePage} from "../Helper/analytics";

export function HomePage() {
    useEffect(LogHomePage, []);

    return (
        <>
            <HomeHeader />
            <About />
            <PlayButton />
            <CardQuote>
                Mafia: The Game is a great way to spend a hilarious afternoon with friends. Use the storyteller
                mode to add another level of laughs!
            </CardQuote>
            <ImageCarousel />
            <Rules />
            <PlayButton />
            <AppFooter />
        </>
    );
}