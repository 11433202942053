import {openJoin} from "../Helper/links";

export function getApiString(url, completion) {
    fetch(url)
        .then(data => data.text())
        .then(str => {
            console.log(str);
            return str;
        })
        .then(completion)
        .catch(error => {
            console.log("getApiString encountered an error;");
            console.log(error);

            setTimeout(() => {
                getApiString(url, completion);
            }, 2000);
        });
}

let errorCount = 0;

export function getApiJson(url, completion) {
    fetch(url)
        .then(data => data.json())
        .then(completion)
        .catch(error => {
            console.log("getApiJson encountered an error;");
            console.log(error);

            // Do not retry because is used for game file
            errorCount++;
            if (errorCount > 8) openJoin();
        });
}