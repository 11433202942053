import {Text, VStack, Center} from "@chakra-ui/react";

export function CardQuote(props) {
    return (
        <Center
            // backgroundColor="red"
        >
            <VStack
                spacing="16px"
                padding="32px"
                borderRadius="lg"
                // marginTop={["16px", "24px", "32px"]}
                // marginBottom={["16px", "24px", "32px"]}
                marginLeft={["4%", "10%", "16%", "22%"]}
                marginRight={["4%", "10%", "16%", "22%"]}
                align="center"
                backgroundColor="gray.1"
            >
                <Text
                    fontSize={["lg", "xl", "2xl"]}
                    fontWeight="bold"
                    // color="white"
                    align="center"
                >
                    {props.children}
                </Text>
                {/*<Text*/}
                {/*    fontSize="lg"*/}
                {/*    align="center"*/}
                {/*    color="white"*/}
                {/*>*/}
                {/*    - some person*/}
                {/*</Text>*/}
            </VStack>
        </Center>
    );
}

export function FullWidthQuote(props) {
    return (
        <Center
            backgroundColor="red"
        >
            <VStack
                spacing="16px"
                marginTop={["32px", "48px", "64px"]}
                marginBottom={["32px", "48px", "64px"]}
                marginLeft={["4%", "10%", "16%", "22%"]}
                marginRight={["4%", "10%", "16%", "22%"]}
                align="center"
            >
                <Text
                    fontSize={["lg", "xl", "2xl"]}
                    fontWeight="bold"
                    color="white"
                    align="center"
                >
                    {props.children}
                </Text>
            </VStack>
        </Center>
    );
}