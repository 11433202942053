import { getApiString } from './getApi';
import { appKey, baseUrl } from './serverConstants';

export function getJoinGame(gameId, playerName, completion) {
    let url = baseUrl;
    url += '/joinGame';
    url += '/' + appKey;
    url += '/' + gameId;
    url += '/' + playerName;
    url += '/null'; // No device token

    getApiString(url, str => { completion(str); });
}