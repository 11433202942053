import {Text, Button, Checkbox, Link, Tooltip} from "@chakra-ui/react";
import {CopyIcon} from "@chakra-ui/icons";
import {AdCardView} from "../Helper/cardView";
import {postToGameWithCompletion} from "../API/postToGame";
import {useState} from "react";
import {allPlayers} from "../Helper/allPlayers";

export function SetupView(props) {
    const [checked, setChecked] = useState(false);
    const [sheriffChecked, setSheriffChecked] = useState(false);

    if (!props.gameFile.tvHost && props.gameFile.players[0].id === props.playerId) {
        const begin = () => {
            const storytellerStr = checked ? "true" : "false";
            const sheriffStr = sheriffChecked ? "true" : "false";
            const message = "begin:" + storytellerStr + ":" + sheriffStr;

            postToGameWithCompletion(props.gameFile.gameId, props.playerKey, message, () => {
               props.forceReload();
            });
        };

        const handleCheck = ({target}) => {
            setChecked(target.checked);
        };
        const handleSheriffCheck = ({ target }) => {
            setSheriffChecked(target.checked);
        };

        const copyLink = () => {
            let textField = document.createElement('textarea');
            textField.innerText = "https://mafiathegame.benrobinson.dev/join?gameId=" + props.gameFile.gameId;
            document.body.appendChild(textField);
            textField.select();
            document.execCommand('copy');
            textField.remove();
        };

        const addBot = () => {
            postToGameWithCompletion(props.gameFile.gameId, props.playerKey, "add-bot", () => {
               props.forceReload();
            });
        };

        return (
            <AdCardView>
                <Text
                    fontSize="4xl"
                    fontWeight="black"
                    color="black"
                >
                    Game ID: {props.gameFile.gameId}
                </Text>

                <Checkbox
                    size="lg"
                    iconColor="red"
                    colorScheme="red"
                    isChecked={checked}
                    onChange={handleCheck}
                >
                    Storyteller mode
                </Checkbox>

                <Checkbox
                    size="lg"
                    iconColor="red"
                    colorScheme="red"
                    isChecked={sheriffChecked}
                    onChange={handleSheriffCheck}
                >
                    The sheriff
                </Checkbox>

                <Button
                    variant="outline"
                    leftIcon={<CopyIcon/>}
                    fontSize="xl"
                    color="black"
                    onClick={copyLink}
                >
                    Copy link
                </Button>

                <Tooltip 
                    hasArrow 
                    label="Maximum 8 bots per game" 
                    isDisabled={props.gameFile.bots.length < 8}
                >
                    <Button
                        variant="outline"
                        fontSize="xl"
                        color="black"
                        onClick={addBot}
                        isDisabled={props.gameFile.bots.length >= 8}
                        disable
                    >
                        Add Bot
                    </Button>
                </Tooltip>

                <Button
                    size="lg"
                    backgroundColor="red"
                    color="white"
                    variant="solid"
                    onClick={begin}
                    isDisabled={allPlayers(props.gameFile).length < 4}
                >
                    Start Game ({allPlayers(props.gameFile).length} players)
                </Button>

                <Text
                    fontSize="xl"
                >
                    Unlimited (human) players may join the game, but bots are limited
                    to 8 per game.
                    To learn how to play,
                    <Link
                        href="https://mafiathegame.benrobinson.dev/how-to-play"
                        isExternal
                    >
                        <i children={" click here."} />
                    </Link>
                </Text>
            </AdCardView>
        );
    }
    return (
        <AdCardView>
            <Text
                fontSize="4xl"
                fontWeight="black"
                color="black"
            >
                Waiting for the host to start the game.
            </Text>
            <Text
                fontSize="xl"
                fontWeight="semibold"
                color="gray.4"
            >
                You are connected to game {props.gameFile.gameId}.
            </Text>
            <Text
                fontSize="xl"
                fontWeight="semibold"
                color="gray.4"
            >
                To learn how to play,
                <Link
                    href="https://mafiathegame.benrobinson.dev/how-to-play"
                    isExternal
                >
                    <i children={" click here."} />
                </Link>
            </Text>
        </AdCardView>
    );
}