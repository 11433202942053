import {CardView} from "../Helper/cardView";
import {Text, HStack, Button} from "@chakra-ui/react";
import {openTerms, openPrivacy, openCookies} from "../Helper/links";
import { LogAuxPage } from "../Helper/analytics";
import { useEffect } from "react";

export function LegalPage() {
    useEffect(() => LogAuxPage('LegalPage'), []);

    return (
        <CardView>
            <Text
                fontSize="4xl"
                fontWeight="bold"
                color="black"
            >
                Legal
            </Text>
            <HStack>
                <Button
                    variant="solid"
                    backgroundColor="red"
                    color="white"
                    onClick={openTerms}
                >
                    Terms and Conditions
                </Button>
                <Button
                    variant="solid"
                    backgroundColor="red"
                    color="white"
                    onClick={openPrivacy}
                >
                    Privacy Policy
                </Button>
                <Button
                    variant="solid"
                    backgroundColor="red"
                    color="white"
                    onClick={openCookies}
                >
                    Cookies Policy
                </Button>
            </HStack>
        </CardView>
    )
}