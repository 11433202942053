import {Text, VStack, Link} from "@chakra-ui/react";
import {AppFooter} from "../Helper/appFooter";
import ReactMarkdown from "react-markdown";
import { LogAuxPage } from "../Helper/analytics";
import { useEffect } from "react";

const markdown = `
Welcome to the world of Mafia: The Game! Here, you can find an in depth description of the functionality of the game and how to play. Let's begin...

**Objective**

The aim of the game depends on your character. If you are the mafia, your aim is to kill all the other players so that there are only two left. If you are a villager, doctor, detective or sheriff, your aim is to eliminate the mafia before they can kill you and the others.

**Setting up the game**

Navigate to [the web join page](https://mafiathegame.benrobinson.dev/join) or [download the app](https://apps.apple.com/app/mafia-the-game/id6475014131). To play, you must first host a game:

- On the app, tap "Host a game". 
- On the web, provide a sensible player name and tap "Host a game".

Then, share the game code or link with your friends. To join a game:

- On the app, enter the game code and tap "Join a Game".
- On the web, enter the game code and a sensible player name. Then, tap "Join a Game".
- On either platform, open the link provided to you by the host of the game.

Note that a game cannot be joined (only made) from Apple TV.

**Game settings**

The host will have the option to customize the game's settings before starting the game.

Storyteller mode allows the game to played truer to the original Mafia game. Every round, a player will be asked to make up a story to explain the events to the other players. This can be extremely funny!

Sheriff mode adds an extra character to the game. Their role is outlined below.

"Add bot" allows a computer player to be added to the game. Each bot acts like a normal player but is controlled by the computer. This is useful if you have too few people to play properly. There is a maximum of 8 bots per game.

**Starting the game and characters**

Ensure you have at least 4 players present and then tap "Start game" to begin playing. Each player will then be presented with their character.

- Mafia - Each night, the mafia decide who to kill. Their aim is to kill every other player so only two remain.
- Detective - Each night, the detective can investigate a player and find out if they are the mafia.
- Doctor - The doctor can choose one player to save from the mafia each night.
- Sheriff - The sheriff's vote counts twice each day so they have extra influence on the outcome of the game.

**Night**

Once the game has started, the night phase will begin. This is an opportunity for the mafia, detective and doctor to make their special moves as detailed above. As soon as these players have made their choices, the game moves on to the vote phase.

**Vote**

The town has arisen and needs to vote on who they believe is the mafia. Remember, the sheriff's vote counts twice but there is always the option to abstain from voting.

**End**

The game ends if only two players are left alive or the mafia has been killed. At this point, the game moves to the end state. Here, the host has the option to reset the game, disband it or leave.

**Support**

Please check out [our support page](https://mafiathegame.benrobinson.dev/support) for more information.
`;

const renderers = {
    link: ({ href, children }) => (
        <Link
            href={href}
            color="red"
        >
            {children}
        </Link>
    )
};

export function HowToPlayPage() {
    useEffect(() => LogAuxPage('HowToPlayPage'), []);

    return (
        <>
            <VStack
                spacing="16px"
                marginTop="64px"
                marginBottom="64px"
                marginLeft={["3%", "10%", "16%", "22%"]}
                marginRight={["3%", "10%", "16%", "22%"]}
                align="left"
            >
                <Text
                    fontSize="4xl"
                    fontWeight="bold"
                    color="red"
                >
                    How To Play
                </Text>
                <ReactMarkdown children={markdown} renderers={renderers} />
            </VStack>
            <AppFooter/>
        </>
    );
}
