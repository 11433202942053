import {Button, VStack, Text} from "@chakra-ui/react";
import {openJoin} from "../Helper/links";

export function PlayButton() {
    return (
        <VStack
            spacing="16px"
            marginTop={["32px", "48px", "64px"]}
            marginBottom={["32px", "48px", "64px"]}
            marginLeft={["4%", "10%", "16%", "22%"]}
            marginRight={["4%", "10%", "16%", "22%"]}
            align="left"
        >
            <Button
                variant="solid"
                backgroundColor="red"
                color="white"
                size="lg"
                align="center"
                onClick={openJoin}
            >
                <Text
                    size="6xl"
                >
                    Start playing now!
                </Text>
            </Button>
        </VStack>
    );
}