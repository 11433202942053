import { appKey, baseUrl } from './serverConstants';
import { getApiJson } from "./getApi";

export function getGameFile(gameId, playerKey, completion) {
    let url = baseUrl;
    url += '/gameFile';
    url += '/' + appKey;
    url += '/' + gameId;
    url += '/' + playerKey;

    getApiJson(url, completion);
}