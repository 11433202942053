import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import img1 from './images/website_screenshots.001.png';
import img2 from './images/website_screenshots.002.png';
import img3 from './images/website_screenshots.003.png';
import img4 from './images/website_screenshots.004.png';
import img5 from './images/website_screenshots.005.png';
import {Box} from "@chakra-ui/react";

export function ImageCarousel() {
    return (
        <Box
            spacing="16px"
            marginTop={["32px", "48px", "64px"]}
            marginBottom={["32px", "48px", "64px"]}
            marginLeft={["4%", "10%", "16%", "22%"]}
            marginRight={["4%", "10%", "16%", "22%"]}
            align="left"
        >
            <Carousel>
                {/*<div>*/}
                    <img src={img1} alt="" />
                {/*</div>*/}
                {/*<div>*/}
                    <img src={img2} alt="" />
                {/*</div>*/}
                {/*<div>*/}
                    <img src={img3} alt="" />
                {/*</div>*/}
                {/*<div>*/}
                    <img src={img4} alt="" />
                {/*</div>*/}
                <img src={img5} alt="" />
            </Carousel>
        </Box>
    );
}