import { appKey, baseUrl } from './serverConstants';
import { getApiString } from "./getApi";

export function postToGame(gameId, playerKey, message) {
    let url = baseUrl;
    url += '/postToGame';
    url += '/' + appKey;
    url += '/' + gameId;
    url += '/' + playerKey;
    url += '/' + message;

    console.log(url);
    getApiString(url, str => console.log(str));
}

export function postToGameWithCompletion(gameId, playerKey, message, completion) {
    let url = baseUrl;
    url += '/postToGame';
    url += '/' + appKey;
    url += '/' + gameId;
    url += '/' + playerKey;
    url += '/' + message;

    console.log(url);
    getApiString(url, completion);
}