import {VStack, Text} from "@chakra-ui/react";

export function Rules() {
    return (
        <VStack
            spacing="16px"
            marginTop={["32px", "48px", "64px"]}
            marginBottom={["32px", "48px", "64px"]}
            marginLeft={["4%", "10%", "16%", "22%"]}
            marginRight={["4%", "10%", "16%", "22%"]}
            align="left"
        >
            <Text
                fontSize={["3xl", "4xl", "5xl"]}
                fontWeight="bold"
                color="red"
            >
                The Rules
            </Text>
            <Text
                fontSize="lg"
            >
                <b>Find players:</b> Meet some friends and get them to download Mafia: The Game (or use this website). Minimum 4 players are required.<br />
            </Text>
            <Text
                fontSize="lg"
            >
                <b>Play:</b> Every player gets assigned a character. The aim is to successfully eliminate the mafia!<br />
            </Text>
            <Text
                fontSize="lg"
            >
                <b>The mafia:</b> Every turn, the mafia decides who to assassinate.<br />
            </Text>
            <Text
                fontSize="lg"
            >
                <b>The doctor:</b> The doctor can select one person to save (including themselves). However, they cannot select the same person twice in a row.<br />
            </Text>
            <Text
                fontSize="lg"
            >
                <b>The detective:</b> Every turn, they can ask the app whether or not someone is the mafia.<br />
            </Text>
            <Text
                fontSize="lg"
            >
                <b>Vote:</b> Everyone votes on who they think is the mafia.<br />
            </Text>
        </VStack>
    );
}