import { Box } from "@chakra-ui/react";
import React, { useEffect } from "react";

export const horizontal1 = "9831633307";
export const horizontal2 = "3982456547";
export const vertical1 = "8087306790";
export const vertical2 = "4006545269";

export function VerticalAd(props) {
    const { adSlot } = props;
    
    useEffect(() => {
      window.adsbygoogle = window.adsbygoogle || []
      window.adsbygoogle.push({})
    }, [])
    
    return (
        <Box padding="20px">
            <ins 
                className="adsbygoogle"
                style={{display: "block", width: "200px"}}
                data-ad-client="ca-pub-2152880073172023"
                data-ad-slot={adSlot}
                data-ad-format="auto"
                data-full-width-responsive="true"
            />
        </Box>
      );
  }

  export function HorizontalAd(props) {
    const { adSlot } = props;
    
    useEffect(() => {
      window.adsbygoogle = window.adsbygoogle || []
      window.adsbygoogle.push({})
    }, [])
    
    return (
        <Box width={["100%", "80%", "60%", "40%"]}>
            <ins 
                className="adsbygoogle"
                style={{display: "block", "maxHeight": "180px", "minHeight": "80px"}}
                data-ad-client="ca-pub-2152880073172023"
                data-ad-slot={adSlot}
                data-full-width-responsive="true"
            />
        </Box>
      );
  }
