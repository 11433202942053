// import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import { HomePage } from './Home/homePage';
import { PlayPage } from './Play/playPage';
import { JoinPage } from './Join/joinPage';
import { LegalPage } from './OtherPages/legalPage';
import { PrivacyPage } from './OtherPages/privacyPage';
import { TermsPage } from './OtherPages/termsPage';
import { SupportPage } from './OtherPages/supportPage';
import { CookiesPage } from './OtherPages/cookiesPage';
import { HowToPlayPage } from "./OtherPages/howToPlay";

function App() {
    const reload = () => window.location.reload();

    return (
        <BrowserRouter>
            <Routes>
                <Route path='/apple-app-site-association' onEnter={reload} />
                <Route path='/play' element={<PlayPage />} />
                <Route path='/join' element={<JoinPage />} />
                <Route path='/legal' element={<LegalPage />} />
                <Route path='/privacy'  element={<PrivacyPage />} />
                <Route path='/terms' element={<TermsPage />} />
                <Route path='/cookies' element={<CookiesPage />} />
                <Route path='/support' element={<SupportPage />} />
                <Route path='/how-to-play' element={<HowToPlayPage />} />
                <Route path='/' element={<HomePage />} />
            </ Routes>
        </BrowserRouter>
    );
}

export default App;
