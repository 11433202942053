import {CardView} from "../Helper/cardView";
import {Text} from "@chakra-ui/react";

export function DeadView() {
    return (
        <CardView backgroundColor="red">
            <Text
                fontSize="4xl"
                fontWeight="black"
            >
                Dead
            </Text>
            <Text
                fontSize="xl"
                fontWeight="semibold"
            >
                Better luck next time!
            </Text>
        </CardView>
    );
}