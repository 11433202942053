import {useState, useEffect} from 'react';
import {
    Text,
    Input,
    Button,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay
} from "@chakra-ui/react";
import {useLocation} from 'react-router-dom';
import {getJoinGame} from '../API/getJoinGame';
import {getMakeGame} from "../API/getMakeGame";
import {setCookie} from '../Helper/cookies';
import {AdCardView} from '../Helper/cardView';
import {ScrollCardView} from "../Helper/scrollCardView";
import {openPlay, openLegal} from '../Helper/links';
import {LogJoinPage} from "../Helper/analytics";

export function JoinPage() {
    useEffect(LogJoinPage, []);

    // Game ID
    const [gameId, setGameId] = useState('');
    const [gameIdProvided, setGameIdProvided] = useState(false);
    const [lastLength, setLength] = useState('');
    const handleGameIdChange = ({target}) => {
        if (target.value.length === 4 && lastLength < target.value.length) {
            setGameId(target.value + "-");
            setLength(5);
        } else {
            setGameId(target.value);
            setLength(target.value.length);
        }
    };

    // Player name
    const [name, setName] = useState('');
    const handleNameChange = ({target}) => {
        setName(target.value);
    };

    // Alert
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertText, setAlertText] = useState('');
    const onAlertClose = () => {
        setAlertOpen(false);
    };

    // Set game id with query param
    let loc = useLocation();
    useEffect(() => {
        try {
            let query = new URLSearchParams(loc.search);
            let val = query.get("gameId");
            if (val !== null) {
                setGameId(val);
                if (validateGameId(val)) {
                    setGameIdProvided(true);
                }
            }
        } catch {
            // Nothing, no gameId was pre-provided
        }
    }, [loc.search]);

    // Handle clicking join
    const [networking, setNetworking] = useState(false);
    const handleServerResponse = str => {
        setNetworking(false);

        if (str === "error") {
            setAlertText("An error was encountered when trying to reach the server. Please note that our servers can take up to a minute to start up following periods of inactivity.");
            setAlertOpen(true);
            setGameIdProvided(false);
            return;
        } else if (str === "bad-id") {
            setAlertText("The game id you provided was invalid. Please try again.");
            setAlertOpen(true);
            setGameIdProvided(false);
            return;
        } else if (str === "bad-state") {
            setAlertText("The game you attempted to join is not accepting new players. Please ensure the game is in the setup phase in order to join it.")
            setAlertOpen(true);
            setGameIdProvided(false);
            return;
        }

        const components = str.split(":");
        if (components.length === 3) {

            // Set the values as cookies
            setCookie("gameId", components[0]);
            setCookie("playerId", components[1]);
            setCookie("playerKey", components[2]);
            setCookie("playerName", name);
            setCookie("doctorLast", "");

            // Open play view
            openPlay();

        } else {
            setAlertText("An error was encountered when trying to reach the server.");
            setAlertOpen(true);
            setGameIdProvided(false);
        }
    }
    const handleHostGame = () => {
        if (networking) {
            return;
        }

        if (!validatePlayerName(name)) {
            setAlertText("Invalid player name. Please ensure your player name is between 3 and 99 characters and only includes letters, numbers and spaces.");
            setAlertOpen(true);
            return;
        }

        setNetworking(true);
        getMakeGame(name, handleServerResponse);
    }
    const handleJoinGame = () => {
        if (networking) {
            return;
        }

        if (!validatePlayerName(name)) {
            setAlertText("Invalid player name. Please ensure your player name is between 3 and 99 characters and only includes letters, numbers and spaces.");
            setAlertOpen(true);
            return;
        }

        if (!validateGameId(gameId)) {
            setAlertText("Invalid game id. Please ensure you have entered the correct game id from the host.");
            setAlertOpen(true);
            setGameIdProvided(false);
            return;
        }

        setNetworking(true);
        getJoinGame(gameId, name, handleServerResponse);
    };

    let content = <></>;
    if (gameIdProvided) {
        content = (
            <AdCardView>
                <Text
                    fontSize="4xl"
                    fontWeight="black"
                    color="red"
                >
                    Join Game
                </Text>
                <Input
                    variant="flushed"
                    placeholder="Player name"
                    size="lg"
                    focusBorderColor="red"
                    borderColor="gray.3"
                    color="black"
                    onChange={handleNameChange}
                    value={name}
                />
                <Button
                    size="lg"
                    backgroundColor="red"
                    color="white"
                    variant="solid"
                    onClick={handleJoinGame}
                >
                    Join Game
                </Button>
                <Text
                    color="gray.4"
                >
                    Appropriate player names only.
                </Text>
                <Text
                    color="gray.4"
                    onClick={openLegal}
                >
                    By clicking "Join Game", you agree that you have read and accept our terms and
                    conditions of use as well as our privacy policy. Click here to read them now.
                </Text>
            </AdCardView>
        );
    } else {
        content = (
            <ScrollCardView>
                <Text
                    fontSize="4xl"
                    fontWeight="black"
                    color="red"
                >
                    Play Mafia: The Game
                </Text>
                <Input
                    variant="flushed"
                    placeholder="Player name"
                    size="lg"
                    focusBorderColor="red"
                    borderColor="gray.3"
                    color="black"
                    onChange={handleNameChange}
                    value={name}
                />
                <Button
                    size="lg"
                    backgroundColor="red"
                    color="white"
                    variant="solid"
                    onClick={handleHostGame}
                >
                    Host New Game
                </Button>
                <Text
                    color="gray.4"
                    align="center"
                >
                    or
                </Text>
                <Input
                    variant="flushed"
                    placeholder="Game code"
                    size="lg"
                    focusBorderColor="red"
                    borderColor="gray.3"
                    color="black"
                    inputMode="numeric"
                    autoComplete="one-time-code"
                    onChange={handleGameIdChange}
                    value={gameId}
                />
                <Input
                    variant="flushed"
                    placeholder="Player name"
                    size="lg"
                    focusBorderColor="red"
                    borderColor="gray.3"
                    color="black"
                    onChange={handleNameChange}
                    value={name}
                />
                <Button
                    size="lg"
                    backgroundColor="red"
                    color="white"
                    variant="solid"
                    onClick={handleJoinGame}
                >
                    Join a Game
                </Button>
                <Text
                    color="gray.4"
                >
                    Appropriate player names only.
                </Text>
                <Text
                    color="gray.4"
                    onClick={openLegal}
                >
                    By clicking "Host New Game" or "Join Game", you agree that you have read and accept our terms and
                    conditions of use as well as our privacy policy. Click here to read them now.
                </Text>
            </ScrollCardView>
        );
    }
    return (
        <>
            {content}

            <AlertDialog
                isOpen={alertOpen}
                onClose={onAlertClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Error
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            {alertText}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button
                                onClick={onAlertClose}
                            >
                                OK
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
}

function validatePlayerName(name) {
    if (name.length < 3 || name.length > 99) {
        return false
    }

    let allNumbersAndLetters = true;
    for (const index in name) {
        const char = name[index];
        if (isLetter(char) || isNumber(char) || isSpace(char)) {
            // All good!
        } else {
            allNumbersAndLetters = false;
        }
    }
    return allNumbersAndLetters;
}

function validateGameId(gameId) {
    console.log(gameId);

    if (gameId.length !== 9) {
        return false;
    }

    let validCharacters = true;
    for (const index in gameId) {
        const char = gameId[index];
        if (index === "4") {
            if (char !== "-") {
                validCharacters = false;
            }
        } else {
            if (!isNumber(char)) {
                validCharacters = false;
            }
        }
    }
    return validCharacters;
}

function isLetter(char) {
    return (/^[a-zA-Z]+$/).test(char);
}

function isNumber(char) {
    return (/^[0-9]+$/).test(char);
}

function isSpace(char) {
    return char === " ";
}