import {VStack, Text} from "@chakra-ui/react";
import {AppFooter} from "../Helper/appFooter";
import ReactMarkdown from "react-markdown";
import { LogAuxPage } from "../Helper/analytics";
import { useEffect } from "react";

const markdown = `
Last updated: 02/28/2021 

We are the developers of Mafia: The Game. This privacy policy describes how we process your personal information and which privacy rights you have when you are using Mafia: The Game. Please contact us at the contact information below if you have any questions or comments.

**Contents:**

1. Personal Information Collection and Use
2. Personal Information Sharing
3. Tracking Technologies
4. Social Logins
5. In-app Purchase Information
6. Privacy Rights of California Residents
7. Privacy Rights of Users in the European Economic Area
8. Children's Personal Information
9. How Long We Keep Your Personal Information
10. How We Protect Your Personal Information
11. Policy Changes
12. Accessibility
13. Google DoubleClick DART Cookie 
14. Contact Us

**1. Personal Information Collection and Use**

We may receive technical information about your device, for example, its IP address or operating system.

In addition, we are collecting and using the following personal information from you:

- Player name. Purpose: To distinguish between players during a game

In addition, data may be collected by Google AdSense to show ads on the site. This is subject to your consent to personalised advertising.

**2. Personal Information Sharing**

We do not share any personal information, except with specific third parties as set out in this policy.

**3. Tracking Technologies**

Mafia: The Game (the website) uses first-party and third-party cookies in order to provide our service. These cookies are not used to track you across websites. Please see our [cookies policy](https://mafiathegame.benrobinson.dev/cookies) for more details.

**4. Social Logins**

Mafia: The Game does not use any social login functionality.

**5. In-app Purchase Information**

Mafia: The Game does not process any in-app purchase information.

**7. Privacy Rights of Users in the European Economic Area**

If you are in the European Economic Area and if you have any questions or would like to exercise any of your rights, please contact us at the contact information below. You have the rights to:

- Request a copy of your personal information
- Request corrections of inaccurate personal information, deletions, or additions to your personal information
- Restrict or object to personal information processing
- Request a portable copy of your personal information for transferring it to another service
- Lodge a complaint with a supervisory authority

**8. Children's Personal Information**

Mafia: The Game cannot be used by children under the age of 13 as per our Terms and Conditions of use.

**9. How Long We Keep Your Personal Information**

We keep personal information provided only for the duration of the game played by the user. The data is then deleted from our servers within 2 hours.

**10. How We Protect Your Personal Information**

We use encryption to protect personal information stored and transmitted online. Only personnel who need the personal information to perform a specific function have access to such. The computers and servers on which we store personal information are kept in a secure environment.

**11. Policy Changes**

When we make material changes to this privacy policy, for example, due to app updates or changes in the law, we will post the updated policy here. The policy will always apply in the version at the time of your use of Mafia: The Game even if you downloaded Mafia: The Game at a time when a previous policy version was applicable.

**12. Accessibility**

This website conforms to the Web Content Accessibility Guidelines (WCAG) 2.0. However, if you are having difficulties obtaining information from it, please contact us at the contact information below. We will try to make the information available to you in another format and answer any question that you may have.

**13. Google DoubleClick DART Cookie**

Google is one of a third-party vendor on our site. It also uses cookies, known as DART cookies, to serve ads to our site visitors based upon their visit to www.website.com and other sites on the internet. However, visitors may choose to decline the use of DART cookies by visiting the Google ad and content network Privacy Policy at the following URL – [https://policies.google.com/technologies/ads](https://policies.google.com/technologies/ads)

**14. Contact Us**

Please contact us if you have any questions or concerns.

Ben Robinson

hello@benrobinson.dev
`;

export function PrivacyPage() {
    useEffect(() => LogAuxPage('PrivacyPage'), []);

    return (
        <>
            <VStack
                spacing="16px"
                marginTop="64px"
                marginBottom="64px"
                marginLeft={["3%", "10%", "16%", "22%"]}
                marginRight={["3%", "10%", "16%", "22%"]}
                align="left"
            >
                <Text
                    fontSize="4xl"
                    fontWeight="bold"
                    color="red"
                >
                    Privacy Policy
                </Text>
                <ReactMarkdown children={markdown} />
            </VStack>
            <AppFooter/>
        </>
    );
}
