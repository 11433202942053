import {VStack, Text} from "@chakra-ui/react";

export function About() {
    return (
        <VStack
            spacing="16px"
            marginTop={["32px", "48px", "64px"]}
            marginBottom={["32px", "48px", "64px"]}
            marginLeft={["4%", "10%", "16%", "22%"]}
            marginRight={["4%", "10%", "16%", "22%"]}
            align="left"
        >
            <Text
                fontSize={["3xl", "4xl", "5xl"]}
                fontWeight="bold"
                color="red"
            >
                About
            </Text>
            <Text
                fontSize="lg"
            >
                Mafia: The Game is a free online game based on the party game Mafia (also known as Werewolf). It's a great
                game to play as an ice-breaker or with friends over a video call.
            </Text>
            <Text
                fontSize="lg"
            >
                Mafia: The Game is available to play on the web. You can also download our apps for iOS, iPadOS and macOS.
                We also offer an application for Apple TV to allow you to host games from the living room.
            </Text>
            <Text
                fontSize="lg"
            >
                Simply make a game, share the code and play!
            </Text>
        </VStack>
    );
}