import mixpanel from 'mixpanel-browser';

mixpanel.init('17246e6e9a14e50f31d36bbd81643e8a');

export function LogHomePage() {
    mixpanel.track('HomePage');
}

export function LogJoinPage() {
    mixpanel.track('JoinPage');
}

export function LogPlayPage() {
    mixpanel.track('PlayPage');
}

export function LogAuxPage(id) {
    mixpanel.track('AuxPage', {id});
}

export function LogJoin() {
    mixpanel.track('Join');
}

export function LogCreate() {
    mixpanel.track('Create');
}
